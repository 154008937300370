import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFeedYoutubeCarousel = lazy(
  () => import("./feed-youtube-carousel-CXF9hKSl.js").then((module) => ({
    default: module.FeedYoutubeCarousel
  }))
);
function FeedYoutubeCarousel({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFeedYoutubeCarousel, { ...props });
}
const useFeedYoutubeCarouselContent = () => {
  return useMemo(
    () => ({
      feedYoutubeCarousel: {
        component: FeedYoutubeCarousel
      }
    }),
    []
  );
};
export {
  LazyFeedYoutubeCarousel as FeedYoutubeCarousel,
  useFeedYoutubeCarouselContent
};
